import * as React from "react"

import PickerScreen from '../../../../../components/pickerScreen'


const Picker = ({ data }) => (
  <PickerScreen
    question="What's your OS?"
    options={[
      {
        label: "macOS",
        link: "/app/tool/apple-keynote/"
      },
      {
        label: "Windows",
        link: "/app/tool/microsoft-powerpoint/"
      },
      {
        label: "Linux",
        link: "/app/tool/pitch-or-google-slides/"
      },
      {
        label: "Chrome OS",
        link: "/app/tool/pitch-or-google-slides/"
      }
  ]}/>
)

export default Picker;


